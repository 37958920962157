import ErrorPage from '@risksmart-app/components/ErrorPages/ErrorPage';
import { FC } from 'react';
import { AuthenticatedAppLayout } from 'src/layouts/AuthenticatedAppLayout';

const Page: FC = () => {
  return (
    <AuthenticatedAppLayout>
      <ErrorPage />
    </AuthenticatedAppLayout>
  );
};

export default Page;
