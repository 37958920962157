import Loading from '@risksmart-app/components/Loading';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';

import {
  Third_Party_Response_Status_Enum,
  useGetReponseByIdQuery,
  useUpdateThirdPartyResponseMutation,
} from '@/generated/graphql';

import { QuestionnaireForm, ResponseData } from './QuestionnaireForm';

const Page: FC = () => {
  const Id = useGetGuidParam('id');

  const navigate = useNavigate();
  const [update] = useUpdateThirdPartyResponseMutation();
  const { data, loading } = useGetReponseByIdQuery({
    fetchPolicy: 'no-cache',
    variables: { Id },
  });

  const responseData = data?.third_party_response_by_pk;
  const readOnly =
    responseData?.Status === Third_Party_Response_Status_Enum.AwaitingReview ||
    responseData?.Status === Third_Party_Response_Status_Enum.Completed ||
    responseData?.Status === Third_Party_Response_Status_Enum.Rejected;

  if (loading) {
    return (
      <PageLayout>
        <Loading />
      </PageLayout>
    );
  }

  if (
    isEmpty(
      data?.third_party_response_by_pk?.questionnaireTemplateVersion?.Schema
    ) ||
    isEmpty(
      data?.third_party_response_by_pk?.questionnaireTemplateVersion?.UISchema
    )
  ) {
    throw new Error('Schema or UISchema is empty');
  }

  const onSave = async (data: ResponseData) => {
    await update({
      variables: {
        Id,
        response: data,
        status: Third_Party_Response_Status_Enum.InProgress,
      },
      context: {
        headers: {
          'x-confirm-change-request': 'true',
        },
      },
    });

    navigate('/');
  };

  const onSubmit = async (data: ResponseData) => {
    await update({
      variables: {
        Id,
        response: data,
        status: Third_Party_Response_Status_Enum.AwaitingReview,
      },
      context: {
        headers: {
          'x-confirm-change-request': 'true',
        },
      },
    });

    navigate('/');
  };

  return (
    <PageLayout
      title={`${responseData?.questionnaireTemplateVersion?.parent?.Title}`}
      counter={`(v${responseData?.questionnaireTemplateVersion?.Version})`}
    >
      <QuestionnaireForm
        schema={responseData?.questionnaireTemplateVersion?.Schema}
        uischema={responseData?.questionnaireTemplateVersion?.UISchema}
        values={responseData?.ResponseData || {}}
        onCancel={() => navigate('..')}
        onSubmit={onSubmit}
        onSave={onSave}
        readOnly={readOnly}
      />
    </PageLayout>
  );
};

export default Page;
