import { AppLayout } from '@cloudscape-design/components';
import Navigation from '@risksmart-app/components/Navigation';
import { useNavMenu } from '@risksmart-app/components/Navigation/useNavMenu';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import ThirdPartyAuth0Context from 'src/providers/ThirdPartyAuth0Context';
import { logoutUrl } from 'src/routes';
import { useNavItems } from 'src/routes/useNavItems';

import styles from './style.module.scss';

type Props = {
  children: ReactNode;
  panelContent?: ReactNode;
};

export const AuthenticatedAppLayout: FC<Props> = ({ children }) => {
  const [navigationOpen, setNavigationOpen] = useNavMenu();

  const navigationWidth = navigationOpen ? 300 : 68;
  const maxContentWidth = 1440;
  const navItems = useNavItems();

  // TODO check `toolsHide` is still needed in UIv3
  // Using the `toolsHide` prop causes the UI to jump, using `visibility: hidden` instead
  return (
    <div className={clsx(styles.layout, 'App', styles.hideTools)}>
      <AppLayout
        headerVariant={'high-contrast'}
        content={
          <div className="flex">
            <div
              className="flex-none h-screen fixed overflow-auto print:hidden"
              style={{
                top: 0,
                bottom: 0,
                width: navigationWidth,
              }}
            >
              <Navigation
                altTheme={false}
                showUserMenu={true}
                navigationOpen={navigationOpen}
                setNavigationOpen={setNavigationOpen}
                logoutUrl={logoutUrl()}
                navItems={navItems}
                authContext={ThirdPartyAuth0Context}
              />
            </div>
            <div
              className={clsx('flex-grow', styles.rsContent)}
              style={{
                paddingLeft: navigationWidth,
              }}
            >
              {children}
            </div>
          </div>
        }
        maxContentWidth={maxContentWidth}
        disableContentPaddings
        navigationOpen={false}
        onNavigationChange={() => {
          // Do nothing. Setting just to avoid warning in console
        }}
        navigationHide={true}
        toolsWidth={350}
        toolsHide={false}
        navigationWidth={navigationWidth}
      />
    </div>
  );
};
