import { TableProps } from '@cloudscape-design/components';
import { useRating } from '@risksmart-app/components/hooks/use-rating';
import Link from '@risksmart-app/components/Link';
import { DATE_TIME_FORMAT_WITH_TIME } from '@risksmart-app/components/providers/i18n';
import { questionnaireUrl } from 'src/routes/urls';

import SimpleRatingBadge from '../../../../components/SimpleRatingBadge/SimpleRatingBadge';
import { ThirdPartyResponse } from './types';

export function useColumnDefinitions() {
  const getStatus = useRating('third_party_response_status');

  const columnDefinitions: TableProps<ThirdPartyResponse>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: 'Title',
        cell: (item) => (
          <Link variant="secondary" href={questionnaireUrl(item.Id)}>
            {item.questionnaireTemplateVersion?.parent?.Title} -{' '}
            {item.questionnaireTemplateVersion?.Version}
          </Link>
        ),
      },
      {
        id: 'status',
        header: 'Status',
        cell: (item) => (
          <SimpleRatingBadge rating={getStatus.getByValue(item.Status)} />
        ),
        sortingField: 'Status',
      },
      {
        id: 'createdAt',
        header: 'Created At',
        cell: (item) => {
          return new Date(item.CreatedAtTimestamp).toLocaleDateString(
            'en-GB',
            DATE_TIME_FORMAT_WITH_TIME
          );
        },
      },
      {
        id: 'updatedAt',
        header: 'Last modified',
        cell: (item) => {
          return new Date(item.ModifiedAtTimestamp).toLocaleDateString(
            'en-GB',
            DATE_TIME_FORMAT_WITH_TIME
          );
        },
      },
    ];

  return columnDefinitions;
}
